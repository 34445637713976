<template>
  <div>
    <h2 class="my-3">Campaign Refresh Jobs</h2>

    <div class="my-4 d-flex align-items-center">
      <div>Filters</div>

      <div class="mx-2 w-40">
        <select class="form-select" v-model="statusFilter">
          <option value="All">All</option>
          <option value="COMPLETED">Completed</option>
          <option value="FAILED">Failed</option>
          <option value="PENDING">Pending</option>
        </select>
      </div>

    </div>

    <div v-for="pipelineJob in availableJobs" class="card my-1">

      <div class="card-body d-flex justify-content-between align-items-center">
        <div>
          <div>
            <strong>{{pipelineJob.metaData.campaignRef}}</strong> ({{pipelineJob.campaignId}})
          </div>
          <div>
            <span v-for="market in pipelineJob.metaData.markets" class="me-1">{{market.marketName}}</span>
          </div>
          <div>
            {{getCustomerName(pipelineJob.customerId)}}
          </div>

          <div class="font-xsm gray-500">
            {{pipelineJob.createdAt}}
          </div>
        </div>

        <div>
          <div>{{formatDate(pipelineJob.activeFrom)}} to {{formatDate(pipelineJob.activeTo)}}</div>
          <div class="my-1"><span class="border rounded py-1 px-1 font-xsm" :class="getJobStatusClasses(pipelineJob.jobStatus)">{{pipelineJob.jobStatus}}</span></div>
          <div><span class="border rounded py-1 px-1 font-xsm" :class="isRunnable(pipelineJob) ? 'border-success text-success bg-green-100' : 'border-danger text-danger bg-red-100'">
            {{isRunnable(pipelineJob) ? 'Runnable' : 'Missing Data'}}</span>
          </div>

          <div v-if="isRunnable(pipelineJob)" class="my-1">
            <button @click="onRunClick(pipelineJob)" class="btn btn-sm btn-primary">Run</button>
          </div>
        </div>
      </div>

    </div>

    <confirm-modal
        key="confirmRePipelineJobModal"
        v-if="showRunModal"
        @noEvent="showRunModal = false"
        :yesEvent="onRerunConfirmed"
        text="Are you sure you want to run this campaign refresh job?">
    </confirm-modal>
  </div>
</template>

<script>
import PipelineService from "@/services/PipelineService";
import * as dayjs from "dayjs";
import ConfirmModal from "@/components/ConfirmModal.vue";
import AppUtil from "@/utils/AppUtil";
import streetmetrics from "@/model";

export default {
  name: 'CampaignRefreshJobs',
  components: {ConfirmModal},
  props: ['prop1', 'prop2'],
  data () {
    return {
      model: streetmetrics.model,
      service: new PipelineService(),
      pipelineJobs: [],
      showRunModal: false,
      currentPipelineJob: null,
      statusFilter: 'All'
    }
  },
  computed: {
    availableJobs() {
      let loadedJobs = this.pipelineJobs

      if (this.statusFilter !== 'All') {
        loadedJobs = loadedJobs.filter((job) => {
          return job.jobStatus === this.statusFilter
        })
      }

      return loadedJobs;
    }
  },
  mounted () {
    this.loadPipelineJobs()
  },
  methods: {
    loadPipelineJobs() {
      this.model.loading = true
      this.service.loadPipelineJobs().then((response) => {
        const jobList = response

        for (let i = 0; i < jobList.length; i++) {
          const job = jobList[i];
          job.metaData = JSON.parse(job.metaData)
          job.activeFrom = job.metaData.activeFrom === null ? Number(job.metaData.flights[0].activeFrom) : Number(job.metaData.activeFrom)
          job.activeTo = job.metaData.activeTo === null ? Number(job.metaData.flights[0].activeTo) : Number(job.metaData.activeTo)
        }

        console.log('Loaded jobs')
        console.log(jobList)
        streetmetrics.utils.array.sort( jobList, 'activeFrom' );
        this.pipelineJobs = jobList
        this.model.loading = false
      }).catch((error) => {
        this.model.loading = false
        this.model.toast.background = 'bg-red';
        this.model.toast.message =
            'There was an error trying load the pipeline jobs.';
      });
    },
    formatDate(dateValue) {
      const date = new Date(dateValue * 1000);
      const formatString = "MM-DD-YYYY";
      return dayjs(date).format(formatString);
    },
    getCustomerName(customerId) {
      return this.model.customers.find(customer => customer.customerId === customerId).customerName
    },
    isRunnable(job) {
      const date = new Date(job.activeTo * 1000);
      const formatString = "YYYY-MM-DD";
      return dayjs(date).format(formatString) > '2024-08-31'
    },
    getJobStatusClasses(jobStatus) {
      if(jobStatus === 'COMPLETED') {
        return 'border-success text-success bg-green-100';
      }
      else if(jobStatus === 'FAILED') {
        return 'border-danger text-danger bg-red-100';
      }

      return 'border-warning text-warning bg-yellow-100';
    },
    onRunClick(pipelineJob) {
      this.currentPipelineJob = pipelineJob
      this.showRunModal = true
    },

    onRerunConfirmed() {
      const rerunPath = '/api/run-pipeline-job'
      this.executeJobRequest(rerunPath)
    },

    executeJobRequest(path) {
      const request = {
        jobId: this.currentPipelineJob.jobId
      }

      const url = new AppUtil().getPipelineUrl() + path
      this.service.post(url, request).then( (response) => {
        console.log('Run successful')
        this.loadPipelineJobs()
      }).catch((error) => {
        console.log('Run pipeline job failed')
        console.log(error)
      });
    }
  }
}
</script>
